.AlertMessage {
    opacity: 0;
    width:290px;
    margin-top:20px;
    margin-bottom:-30px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    transition: opacity 400ms ease-in-out;
}
.AlertMessage.active {
    opacity: 1;
}
.AlertMessage > div {
    border-radius: 13px;
    border:1px solid #FFADAD;
    padding:10px 20px;
    font-size: 9pt;
    color: #F85656;
    font-weight: bold;

}