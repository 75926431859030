.LoginPage {
    width:100vw;
    min-height:100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(0deg, #EDF0F4 0%, #FFF 100%);

}

.LoginPage-form {
    display: flex;
    flex-direction: column;
    width:290px;
    align-items:stretch;
    padding-top:40px;
    
}
.LoginPage-form input {
    width:260px;
    margin:7px 0px;
    background-color: #FFF;
    border:1px solid #CADBED;
}
.LoginPage-form button {
    width:290px;
    margin:7px 0px;
}