.Title-link {
  font-size: 18pt;
  color: black;
}

.Title-link.active {
  color: blue;
}

.App {
  width: 100vw;
  height: 100vh;
  background-color: #FFF;
  transition: background-color 1s;
}

.App.loading {
  background-color: #EDF0F4;

}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}


/* default set */

input {

  border: 0px;
  border-radius: 5px;
  padding: 10px 15px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  
}
button {
  border:0px;
  border-radius: 5px;
  padding: 10px 0px;
  color:white;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: #3672B9;
  cursor:pointer;

}
button:hover {
  /* color:#D9E5F2; */
  background-color: #195193;
}

button:active {
  background-color: #255EA2;
}