.PanelList {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
}
.PanelList-create-button {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin:0px -17px;
    padding:13px 17px;
    border-radius: 5px;
    background:#F4F7FA;
    /* border-bottom:1px solid #EFEFEF; */
    /* border:1px solid #EFEFEF; */
    /* background:#EFEFEF; */
}
.PanelList-create-button > button,
.PanelList-list > .empty-list > .message > button {
    /* margin:20px; */
    margin-left:6px;
    padding-left:20px;
    padding-right:20px;
}
.PanelList-list {
    margin-top:40px;
    display: flex;
    flex-flow: row wrap;
}
.PanelList-list > .empty {
    flex-grow: 1;
}

.PanelList-list > .empty-list {
    width: 100%;
    color:#5e6c79;
    flex-grow: 1;
    text-align: center;
}

.PanelList-list > .empty-list > h3 {
    color: #3672B9;
    font-size: 20pt;
}
.PanelList-list > .empty-list > .message{
    margin-top:30px;

}
.PanelList-list > .empty-list > .message > button {
    margin-top:10px;
    margin-top:10px;
}
.PanelLoading {
    border-radius:59px;
    width:200px;
    height:200px;
    background-color: #d4dee7;
    animation: panel-loading 500ms  infinite alternate-reverse;
    margin:0px 40px 20px 0px;
}
@keyframes panel-loading {
    from {
        opacity:1;

    }
    to {
        opacity:0.5;

    }
    
}

.EmptyMessage {
    display:flex;
    flex-direction: column;
    align-items: center;
}


.PanelQR {
    position:fixed;
    top:0px;
    left:0px;
    right:0px;
    bottom:0px;
    backdrop-filter: blur(10px);
    background-color: #FFFFFF55;
    z-index: 100;
    opacity: 0;
    transition:all;
    transition-duration: 400ms;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.PanelQR.shown {
    opacity: 1;
    visibility: visible;
}
.PanelQR > div.code {
    padding:20px;
    border-radius: 10px;
    background-color: white;
    border: 1px solid #3672B9;
}
.PanelQR > div.info {
    color:#5e6c79;
    margin-top:20px;
    font-size: 12pt;
}
.PanelQR > div > div {
    font-size:8.5pt;
    letter-spacing: -0.03em;
    text-align: center;
    border-radius: 5px;
    background-color: #EFEFEF;
    padding: 5px 0px;
    margin:10px 0px 0px 0px;
}