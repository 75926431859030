.MainScaffold-1 {
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;

}
.MainScaffold-top {
    margin: 70px 100px 40px 100px;
    /* margin-top:70px;
    margin-left:100px; */
    display: flex;
    flex-direction: row;

}
.MainScaffold-menu {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-start;
}
.MainScaffold-logo-spacer{
    width:80px;
}
.MainScaffold-menu-item {
    font-weight: bold;
    text-decoration: none;
    margin:0px 25px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color:#000;

}

.MainScaffold-menu-item:visited {
    color:#000;

}
.MainScaffold-menu-item:hover{
    color:#899CB2;
}

.MainScaffold-menu-item:focus{
    color:#3672B9;
}
.MainScaffold-menu-item.active {
    color:#1D85FF;
}



.MainScaffold-body {
    margin:0px 100px 60px 100px;
    /* margin-top:60px;
    margin-left:100px; */
}