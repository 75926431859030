.lds-dual-ring {
    display: inline-block;
    width: 40px;
    height: 40px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 34px;
    height: 34px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #3672B9;
    border-color: #3672B9 transparent #3672B9 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loader-container{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width:100vw;
    height:100vh;
}

.SequenceBlock:last-child {
    border:none;
}
.SequenceBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding:10px 0px;
    border-bottom:1px solid #EFF3F7;
}

.SequenceBlock > .white-block {
    border-radius: 3px;
    padding:3px 5px;
    background-color: white;
    color: #808080;
    font-size: 6.5pt;
    font-weight: 700;
    letter-spacing: -0.05em;
    margin-right:7px;

}
.SequenceBlock > .transparent-block{
    font-size: 11pt;
    font-weight: 700;
    color:#617893;
    margin-right:20px;
    flex-basis: 0px;
}
.SequenceBlock > .transparent-block.chr{
    flex-grow:1;
}
.SequenceBlock > .transparent-block.loc{
    flex-grow:2;
}
.SequenceBlock > .transparent-block.seq{
    flex-grow:2;
}
.SequenceBlock > .transparent-block.description{
    flex-grow:4;
    font-weight: normal;
    color: #666;
}


.SequenceBlock > .delete{
    cursor: pointer;
    visibility: hidden;
}
.SequenceBlock:hover > .delete{
    visibility: visible;
}
.SequenceBlock:hover > .delete:hover{
    opacity: 0.5;
}
.SequenceBlock:hover > .delete:focus,
.SequenceBlock:hover > .delete:active{
    opacity: 0.2;
}