.PanelEdit {
    background-color: #DFE5EA;
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;

}

.PanelEdit>.statusbar {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 40px;
    background-color: #F5F6F7;
    border-top: 1px solid #C3CDD6;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 1000;
}

#status-circle {
    margin-left: 20px;
    margin-right: 10px;
    border-radius: 20px;
    width: 14px;
    height: 14px;
    background-color: #E4E8EB;
    /* border:1px solid #C3CDD6; */
}

#status-circle.loading {
    animation-name: loading-animation;
    animation-iteration-count: infinite;
    animation-duration: 300ms;
    animation-direction: alternate;

}

#status-circle.editing {
    background-color: #EA4C4C;
}

@keyframes loading-animation {
    from {
        background-color: transparent;
    }

    to {
        background-color: #8D97A2;
    }

}

#status-text {
    font-size: 9.5pt;
    color: #A0AFC1;

}

#status-text.editing {
    color: #EA4C4C;
}

#status-text.loading {
    color: #8D97A2;
}

.PanelEdit>.container {
    max-width: 690px;
    flex-grow: 1;
    padding: 0px 20px 70px 20px;

}

#CloseButton {
    position: fixed;
    top: 50px;
    right: 50px;
    width: 40px;
    height: 40px;
}

#CloseButton:hover {
    opacity: 0.5;
}

.PanelTitleContainer {
    margin-top: 50px;

}

.PanelTitleContainer>div {
    font-size: 9pt;
    opacity: 0.4;
    margin: 0px 0px 3px 5px;
    letter-spacing: 0.1em;

}

.PanelTitleContainer>input {
    font-size: 17pt;
    background-color: #FFFFFF77;
    width: 250px;

    transition-duration: 300ms;
    transition-property: background-color;

}

.PanelTitleContainer>input:hover {
    background-color: #FFFFFF;
}

#PanelMenu {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 20px;

}

#PanelMenu>button {
    padding: 15px 20px;
}

#PanelMenu>div {
    margin-top: 20px;
    text-align: center;
    font-size: 10pt;
    opacity: 0.5;
    cursor: pointer;
}

#PanelMenu>div:hover {
    opacity: 1;
    color: #EA4C4C;
}

#PanelMenu>div:focus,
#PanelMenu>div:active {
    opacity: 0.5;

}

.PanelItem {
    margin: 30px 0px;
    background-color: #FFFFFF;
    border-radius: 7px;
    padding: 20px;
    box-shadow: 2px 2px 10px 0px #00000011;
    transition-property: box-shadow;
    transition-duration: 300ms;
}

.PanelItem:hover {
    box-shadow: 2px 2px 20px 0px #3376C655;
}

.PanelItem>.title-row {
    display: flex;
    flex-direction: row;

}

.PanelItem>.title-row input {
    margin-left: -3px;
    font-size: 17pt;
    font-weight: bold;
    padding: 2px 7px;
    border: 1px solid transparent;
    width: auto;
    transition-property: background-color, border;
    transition-duration: 300ms;
}

.PanelItem>.title-row input:hover {
    border: 1px solid #EFEFEF;
    background-color: #e7ecf1;
}

.PanelItem .input-title {
    font-size: 9pt;
    opacity: 0.4;
    margin: 0px 5px 0px 5px;
    letter-spacing: 0.1em;

}

.PanelItem>.title-row>.title-left {
    flex-grow: 1;
}

.PanelItem>.title-row>.title-left>.gene-description {
    font-size: 9pt;
    color: #6884A5;
    margin-left: 5px;
    letter-spacing: -0.05em;
}

.PanelItem>.title-row>.title-left>.gene-description.loading {
    color: #EFEFEF;
}

.PanelItem>.title-row>.title-left>.gene-description.error {
    color: red;
}

.PanelItem>.title-row select {
    border-radius: 20px;
    border: 1px solid #EFEFEF;
    background-color: transparent;
    -o-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 5px 10px;
    padding-right: 25px;
    margin-top: 5px;
    /* background: transparent; */
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='9' height='7' viewBox='0 0 10 9'><path d='M5,0l5,9H0Z' transform='translate(10 9) rotate(180)' fill='rgb(208,208,208)'/></svg>");
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: 90%;
    font-size: 10pt;
}

.PanelItem>.bottom-row {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.PanelItem>.bottom-row>div {
    opacity: 0;
    margin-right: 10px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 10pt;
    color: #EA4C4C;
    border-radius: 5px;
}

.PanelItem:hover>.bottom-row>div {
    opacity: 1;
}

.PanelItem:hover>.bottom-row>div:hover {
    background-color: #EA4C4C;
    color: white;

}

select::-ms-expand {
    display: none;
}

.PanelItem button {
    font-weight: normal;
    margin: 5px 5px;
    background-color: transparent;
    color: #3672B9;
    letter-spacing: -0.05em;
    padding-left: 15px;
    padding-right: 15px;
}

.PanelItem button.gray {
    color: #bbbbbb;
}

.PanelItem button:hover {
    background-color: #F8F9FA;

}

.PanelItem button.gray:hover {
    background-color: #f0f0f0;

}

.PanelItem button:focus,
.PanelItem button:active,
.PanelItem button.activated {
    background-color: #e2e6eb;
    color: #184f94;

}

.PanelItem button.gray:focus,
.PanelItem button.gray:active {
    background-color: #dddddd;
    color: #c0c0c0;

}



.PanelItem>.mutations {
    margin-top: 40px;
}

.PanelItem>.mutations>.mutation-box {
    border: 1px solid #DFE5EA;
    border-radius: 5px;
    background-color: #F8F9FA;
    margin: 5px 5px;

}

.PanelItem>.mutations>.mutation-box>.mutation-bottom {
    background-color: white;
    margin: 0px 0px 0px 0px;
    border-radius: 0px 0px 5px 5px;

}

.PanelItem>.mutations>.mutation-box>.info {
    font-size: 10pt;
    color: #000;
    opacity: 0.3;
    margin: 20px 17px;
}

.PanelItem>.mutations>.mutation-box>.list {
    max-height: 200px;
    overflow-y: auto;
    padding: 10px 17px 15px 17px;
}

.PanelItem .mutation-modal-background {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgb(255, 255, 255, 0.3);
    backdrop-filter: blur(1px);
    z-index: 10;
    opacity: 0;
    transition: all;
    transition-duration: 400ms;
}

.PanelItem .mutation-modal {
    position: absolute;
    background-color: white;
    width: 473px;
    height: 160px;
    z-index: 100;
    border-radius: 5px;
    border: 1px solid #DDDDDD;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.16);
    opacity: 0;
    transition: opacity, visibility;
    transition-duration: 200ms;
}

.PanelItem .mutation-modal.genotyping {
    width: 600px;
    height: 80px;
}


.PanelItem .mutation-modal>div {
    display: flex;
    flex-direction: column;
    height: calc(100% - 40px);
    margin: 20px;
    justify-content: space-evenly;

}

.PanelItem .mutation-modal>div>div {
    display: flex;
    flex-direction: row;
}

.PanelItem .mutation-modal>div>div>div {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    align-items: stretch;
    flex-basis: 0px;
}

.PanelItem .mutation-modal>div>div>div>span {
    font-size: 9pt;
    color: #8D97A2;
    letter-spacing: -0.05em;
}

.PanelItem .mutation-modal>div>div>div>input {
    background-color: #F0F6FA;
    width: calc(100% - 30px);
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12pt;
}

.PanelItem .mutation-modal>div>div>div.loc {
    flex-grow: 4;

}

.PanelItem .mutation-modal>div>div>div.description {
    flex-grow: 5;

}

.PanelItem .mutation-modal>div>div>div.haplotype{
    margin-right:20px;
    

}
.PanelItem .mutation-modal>div>div>div.interpretation{
    flex-grow:5;
}
.PanelItem .mutation-modal>div>div>div.submit-button {
    justify-content: flex-end;


}

.PanelItem .mutation-modal.genotyping>div>div>div.submit-button {
    flex-grow: 3;

}

.PanelItem .mutation-modal>div>div>div.submit-button>button {
    background-color: #3672B9;
    color: white;
    font-size: 9.5pt;
    font-weight: bold;
}

.PanelItem .mutation-modal>div>div>div.submit-button>button:hover {
    background-color: #235EA3;

}

.PanelItem .mutation-modal>div>div>div.submit-button>button:focus,
.PanelItem .mutation-modal>div>div>div.submit-button>button:active {
    background-color: #164d8b;

}



/* genotypes */


.PanelItem>.genotypes {
    margin-top: 40px;
}

.PanelItem>.genotypes>.empty {
    padding: 20px;
    background-color: #F8F9FA;
    border-radius: 5px;
    border: 1px solid #E9ECF0;
    margin: 5px 0px;
    font-size: 10pt;
    color: #00000055;
}


.Genotype {
    padding: 13px;
    background-color: #F8F9FA;
    border-radius: 5px;
    border: 1px solid #E9ECF0;
    margin: 10px 0px;
    transition-duration: 300ms;
    transition-property: border, box-shadow;
    box-shadow: none;
    cursor: default;
}

.Genotype.hidden {
    cursor: pointer;
}

.Genotype:hover {
    border: 1px solid #92a0b3;
    box-shadow: 2px 2px 20px #00000011;
}

.Genotype>.title-row {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.Genotype>.title-row input {
    flex-grow: 1;
    margin-left: 2px;
    font-size: 10pt;
    font-weight: bold;
    padding: 5px 5px;
    border: 1px solid transparent;
    width: auto;
    background-color: transparent;
    color: #3672B9;
    transition-duration: 300ms;
    transition-property: border, background-color;
}

.Genotype.hidden>.title-row input {
    color: #617893;
    user-select: none;
    cursor: pointer;

}

.Genotype:not(.hidden)>.title-row input:hover {
    border: 1px solid #EFEFEF;
    background-color: #FFFFFF;
}

.Genotype>.title-row>div.remove {
    padding: 5px 7px;
    margin-right: 13px;
    margin-left: 23px;
    font-size: 9pt;
    color: #EA4C4C;
    cursor: pointer;
    ;
    opacity: 0;
    border-radius: 5px;
}

.Genotype:hover>.title-row>div.remove {
    opacity: 1;
}

.Genotype:hover>.title-row>div.remove:hover {
    color: #FFF;
    background-color: #EA4C4C;

}

.Genotype:hover>.title-row>div.remove:focus,
.Genotype:hover>.title-row>div.remove:active {
    background-color: #b32323;

}

.Genotype>.title-row>div.toggle>svg {
    transform: rotate(180deg);
    transition: transform;
    transition-duration: 400ms;
    transition-timing-function: ease-out;
}

.Genotype.hidden>.title-row>div.toggle>svg {
    transform: rotate(0deg);
}

.Genotype>.title-row>div.toggle {
    margin-right: 5px;
    padding: 5px 7px;
    cursor: pointer;
    border-radius: 10px;



}

.Genotype>.title-row>div.toggle #caret {
    stroke: #8D97A2;
}

.Genotype>.title-row>div.toggle:hover {
    background-color: #8D97A2;
}

.Genotype>.title-row>div.toggle:hover #caret {
    stroke: #FFF;
}

.Genotype>.body {
    background-color: #FFFFFF;
    border-radius: 5px;
    margin: 7px;
    padding: 10px;
    transition: all;
    transition-duration: 400ms;
    transition-timing-function: ease-out;
    opacity: 1;

}

.Genotype.hidden>.body {
    height: 0px;
    overflow: hidden;
    padding: 0px;
    margin: 0px;
    opacity: 0;
}

.Genotype>.body>.mutation-bottom {
    margin-top: 0px;
    border-top: 1px solid #EFEFEF;
}

.Genotype>.body>.info {
    font-size: 10pt;
    color: #000;
    opacity: 0.3;
    margin: 20px 17px;
}

.Genotype>.body>.list {
    max-height: 200px;
    overflow-y: auto;
    padding: 10px 17px 15px 17px;
}

.Genotype>.body>.list .white-block {
    background-color: #eaedf0;
}

.GenotypeReference {
    margin-top: 40px;
    margin-bottom: 0px;
}

.GenotypeReference>input {
    margin-top: 5px;
    background-color: #F8F9FA;
    border: 1px solid #E9ECF0;
    width: calc(100% - 30px);
}


.GenotypeInterpretations {
    margin-top: 40px;
}

.GenotypeInterpretations>.box {
    border: 1px solid #DFE5EA;
    border-radius: 5px;
    background-color: #F8F9FA;
    margin: 5px 5px;


}

.GenotypeInterpretations>.box>.list {
    max-height: 200px;
    overflow-y: auto;
    padding: 15px 17px 15px 17px;

}
.GenotypeInterpretations>.box>.list >div {
    margin:-10px;
    padding:10px;
    margin-bottom:20px;
    border-radius: 10px;
    transition: all;
    transition-duration: 300ms;
    border:1px solid transparent;
    
}
.GenotypeInterpretations>.box>.list >div:hover {
    background-color: white;
    border:1px solid #cbd4df;
    box-shadow: 0px 2px 10px #00000022;
    
}
.GenotypeInterpretations>.box>.list >div:last-child {
    margin-bottom:10px;
}

.GenotypeInterpretations>.box>.list >div > .haplotype {
    color: #AAA;
    font-weight:bold;
    font-size:10pt;
    margin-left:5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.GenotypeInterpretations>.box>.list >div > .haplotype > .title{
    font-weight:normal;
    font-size: 8pt;
    margin-right:10px;
}
.GenotypeInterpretations>.box>.list >div > .haplotype > .hapName {
    color: #164d8b;
    margin: 0px 5px;
}
.GenotypeInterpretations>.box>.list >div > .haplotype > .spacer{
    flex-grow:1;
}
.GenotypeInterpretations>.box>.list >div > .haplotype > .delete{
    /* flex-grow: 1; */
    text-align: right;
    padding:5px 10px;
    opacity: 0;
    cursor: pointer;
    transition: opacity;
    transition-duration: 300ms;
    color:#EA4C4C;
    font-weight: normal;
    border-radius: 5px;

}
.GenotypeInterpretations>.box>.list >div:hover >.haplotype >.delete {
    opacity:1;

}
.GenotypeInterpretations>.box>.list >div:hover >.haplotype >.delete:hover {
    background-color:#EA4C4C;
    color: white;

}
.GenotypeInterpretations>.box>.list >div:hover >.haplotype >.delete:focus,
.GenotypeInterpretations>.box>.list >div:hover >.haplotype >.delete:active
{
    opacity:0.5;
}
.GenotypeInterpretations>.box>.list >div > .interpretation{
    margin-top:7px;
    font-size: 11pt;
    border-radius:5px;
    background-color: #FFF;
    /* border:1px solid #e7ecf1; */
    color:#555;
    padding:8px 13px;
}

.GenotypeInterpretations>.box>.empty {
    font-size: 10pt;
    color: #000;
    opacity: 0.3;
    margin: 20px 17px;
}

.GenotypeInterpretations select {
    border-radius: 20px;
    border: 1px solid #EFEFEF;
    background-color: transparent;
    -o-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 5px 10px;
    padding-right: 25px;
    margin-top: 5px;
    /* background: transparent; */
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='9' height='7' viewBox='0 0 10 9'><path d='M5,0l5,9H0Z' transform='translate(10 9) rotate(180)' fill='rgb(208,208,208)'/></svg>");
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: calc(100% - 10px);
    font-size: 12pt;
    margin-bottom:10px;
}
.GenotypeInterpretations select:hover {
    background-color: #F0F6FA;

}